'use client';

import { useEffect, useState } from 'react';

const AvailableStatesDescription = () => {
  const [activateNavigationFAQ, setActivateNavigationFAQ] = useState(false);

  useEffect(() => {
    if (activateNavigationFAQ) {
      const faq = document.getElementById('faq');
      const faqItem = document.getElementById('faq-item-4');

      if (faq && faqItem) {
        faq.scrollIntoView({ behavior: 'smooth' });
        faqItem.click();
        setActivateNavigationFAQ(false);
      }
    }
  }, [activateNavigationFAQ]);

  return (
    <p className="mx-auto my-4 max-w-[85%] text-center md:mb-6 md:font-light">
      Our providers offer convenient virtual care in{' '}
      <span
        className="md:cursor-pointer md:underline"
        onClick={() => {
          setActivateNavigationFAQ(true);
        }}
      >
        41 states.
      </span>
    </p>
  );
};

export default AvailableStatesDescription;
