'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useState, type FC } from 'react';

import { Arrow } from 'assets/icons';
import { type ServiceItem as ServiceItemProps } from 'components/Services/utils';
import useViewport from 'hooks/useViewport';

const ServiceItem: FC<ServiceItemProps> = ({ category, iconSrc, links, plantSrc, plantClass }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile, isTablet } = useViewport();

  if (links.length === 0) return null;

  if (!isMobile && !isTablet) {
    return (
      <li key={category} className="flex cursor-default flex-col items-center bg-green-50">
        <div className="relative -top-4 z-10">
          <Image alt={`${category} Icon`} className="h-[4.5rem] w-[4.5rem]" src={iconSrc} />
          <Image alt="" className={plantClass} src={plantSrc} />
        </div>
        <div className="relative flex w-full flex-col items-center gap-3">
          <h3 className="text-sm font-medium -tracking-tight">{category}</h3>
          <div className="mb-2 h-[1px] w-1/2 bg-[#000000]" />
          <div className="w-full place-self-start bg-green-50">
            <ul className="relative mx-auto mb-10 flex flex-col gap-4 px-4 text-[.6875rem]">
              {links.map(
                ({ label, href }) =>
                  href && (
                    <li key={label}>
                      <Link
                        className="text-green-900 focus:font-semibold focus:text-green-800 focus:underline hover:font-semibold hover:text-green-800 hover:underline"
                        href={href}
                      >
                        {label} <span className="font-semibold">&gt;</span>
                      </Link>
                    </li>
                  ),
              )}
            </ul>
          </div>
        </div>
      </li>
    );
  }

  return (
    <div>
      <li
        key={category}
        className="flex cursor-pointer items-center justify-between bg-green-50 py-1 pl-4 pr-[1.4219rem]"
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
      >
        <div className="flex items-center gap-2">
          <Image
            alt={`${category} Icon`}
            className="h-[3.75rem] w-[3.75rem]"
            sizes="100"
            src={iconSrc}
          />
          <h3 className="font-medium -tracking-tight">{category}</h3>
        </div>
        <Image
          alt={!isExpanded ? 'Expand question item icon' : 'Collapse question item icon'}
          className={`${
            isExpanded ? '-rotate-180' : ''
          } h-[9px] w-[16.5px] transition-all duration-300 ease-in-out`}
          src={Arrow}
        />
      </li>
      {isExpanded && (
        <div className="bg-green-50 pb-8 pl-[5.25rem]">
          <ul className="flex flex-col gap-4">
            {links.map(({ label, href }) => (
              <li key={label} className="max-w-[75%]">
                <a
                  className="text-green-900 focus:font-semibold focus:text-green-800 focus:underline"
                  href={href}
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                  target="_blank"
                >
                  {label} <span className="font-semibold">&gt;</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ServiceItem;
